import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvDpgFYQHD8QppjMcrv1NfiwLlnkOB2Io",
  authDomain: "tradevelocitypro-4bf48.firebaseapp.com",
  projectId: "tradevelocitypro-4bf48",
  storageBucket: "tradevelocitypro-4bf48.appspot.com",
  messagingSenderId: "5543136150",
  appId: "1:5543136150:web:08d5ee473c41dd164febb7",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDtmNsjSnruKS6ZCs3jwqec0QMqGqGKQWk",
//   authDomain: "finance-v2-e1682.firebaseapp.com",
//   projectId: "finance-v2-e1682",
//   storageBucket: "finance-v2-e1682.appspot.com",
//   messagingSenderId: "133386766551",
//   appId: "1:133386766551:web:e0236afa4a4dc881e20892",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
